import { addLocaleData } from 'react-intl';

import 'moment/locale/ru';
import antdLocale from 'antd/lib/locale-provider/ru_RU';
import intlLocale from 'react-intl/locale-data/ru';
import messages from './messages.po';

addLocaleData(intlLocale);

export default { antdLocale, intlLocale, messages };
